.hero {
  position: relative;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url("https://nmpp.aeonibrokersreward.com.my/wp-content/uploads/2024/08/shutterstock_2190937553.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  margin-top: var(--header-height);
}

.heroContent {
  position: relative;
  z-index: 3;
  width: 100%;
  text-align: center;
  padding: 0 20px;
}

.merchantName {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 1.2;
  background: linear-gradient(
    45deg,
    #16ff00,
    /* Vibrant orange */ #ffffff,
    /* Vibrant green */ #ffffff,
    /* Cyan */ #ffffff /* Yellow */
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textShine 8s ease-in-out infinite alternate;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.merchantCode {
  font-size: 1.5rem;
  opacity: 0.9;
  margin-top: 1rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 300;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  z-index: 2;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
    filter: hue-rotate(0deg) brightness(1);
  }
  50% {
    background-position: 100% 50%;
    filter: hue-rotate(180deg) brightness(1.2);
  }
  100% {
    background-position: 0% 50%;
    filter: hue-rotate(360deg) brightness(1);
  }
}

@media (max-width: 768px) {
  .hero {
    height: 500px;
  }

  .merchantName {
    font-size: 2.5rem;
  }

  .merchantCode {
    font-size: 1.2rem;
    letter-spacing: 2px;
  }
}
