.productCard {

  background-color: #ffffff;

  border-radius: 16px;

  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

  overflow: hidden;

  transition: all 0.3s ease;

  display: flex;

  flex-direction: column;

  height: 100%;

  border-left: 4px solid var(--card-color);

}



.productCard:hover {

  transform: translateY(-5px);

  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);

}



.cardContent {

  padding: 2.5rem;

  display: flex;

  flex-direction: column;

  height: 100%;

}



.title {

  font-size: 2.2rem;

  font-weight: 700;

  color: #333;

  margin-bottom: 1rem;

  letter-spacing: -0.5px;

}



.description {

  font-size: 1rem;

  color: #666;

  margin-bottom: 2rem;

  flex-grow: 1;

  line-height: 1.6;

}



.buttonContainer {

  display: flex;

  flex-direction: column;

  gap: 0.75rem;

}



.button {

  padding: 0.9rem 1.2rem;

  border-radius: 8px;

  text-align: center;

  font-weight: 600;

  transition: all 0.3s ease;

  letter-spacing: 0.5px;

  font-size: 0.9rem;

  text-decoration: none;

  color: #333;

  background-color: #f4f4f4;

  border: none;

  cursor: pointer;

}



.button:hover {

  background-color: #983986;

  color: white;

  transform: translateY(-2px);

}



.nmppCard .buttonContainer {

  position: relative;

}



.nmppCard .applicationButton {

  position: absolute;

  top: -3.9rem; /* Adjust this value to move the application button up or down */

  left: 0;

  right: 0;

}



.nmppCard .button:not(.applicationButton) {

  margin-top: 1.75rem; /* Add space between non-application buttons */

}



@media (max-width: 768px) {

  .cardContent {

    padding: 2rem;

  }



  .title {

    font-size: 2rem;

  }



  .nmppCard .applicationButton {

    top: -5rem; /* Adjust for smaller screens if needed */

  }

}

