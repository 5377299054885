.productGridWrapper {

  background-color: #f8f9fa;

  padding: 4rem 2rem;

}



.productGrid {

  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  gap: 2rem;

  max-width: 1200px;

  margin: 0 auto;

}



@media (max-width: 768px) {

  .productGridWrapper {

    padding: 3rem 1rem;

  }



  .productGrid {

    grid-template-columns: 1fr;

  }

}

