:root {
  --header-height: 100px;
  --header-shrink-height: 70px;
  --background-color: rgba(255, 255, 255, 0.95);
  --box-shadow-color: rgba(0, 0, 0, 0.1);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px var(--box-shadow-color);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1000;
}

.headerContent {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: calc(var(--header-height) * 0.6);
  width: auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.header.shrink {
  height: var(--header-shrink-height);
}

.header.shrink .logo {
  height: calc(var(--header-shrink-height) * 0.6);
}

.header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, #3498db, #2ecc71, #f1c40f, #e74c3c);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.header.shrink::after {
  transform: scaleX(1);
}
